import { mdiClockOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import React from 'react';
import { Card, Container, ContainerFlex } from '../../components/styled/Styled';

const MessageItem = ({ item }) => {
    return (
        <Card width="12" mv="16" border="solid" color="color-white" borderColor="color-primary" borderWidth="1px">
            <Card px="16" fontSize="14px" radiusbl="0" radiusbr="0" color="color-primary-a50" fontWeight="500">
                <Container children={`${item.owner.firstname} ${item.owner.lastname} commented`} />
                <ContainerFlex align="center" mt="4">
                    <Icon path={mdiClockOutline} size={"10px"} />
                    <Container mh="4" fontWeight="100" fontSize="11px" children={format(new Date(item.create_at), "dd/MM/yyyy HH:mm")} />
                </ContainerFlex>
            </Card>
            <Container width="12" height="1px" color="color-gray" />
            <Container px="16" children={<div style={{ whiteSpace: "pre-line" }} children={item.text} />} />
        </Card>
    );
};

export default MessageItem;