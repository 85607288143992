import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Button, Container, ContainerFlex, CustomInput, SelectInput, TextLocalized } from '../../components/styled/Styled';
import img_home from '../../img/background-home.png';
import { HttpConnection, HttpOperations } from '../../components/util/HttpConnection';
import NetworkingView from './NetworkingView';
import { LanguageContext } from '../../components/providers/LanguageProvider';
import { chains, countries, filterDefault, types } from '../../constants/model';
import { useNavigate } from 'react-router';

const NetworkingFilterView = () => {
    const [items, setItems] = useState([]);
    const [formData, setFormData] = useState({ ...filterDefault });

    const { dictionary } = useContext(LanguageContext);
    const history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    useEffect(() => {
        HttpConnection(HttpOperations.networkings,
            item => setItems(item.item),
            error => console.log(error));
    }, [])

    const filterItems = () => {
        let itemsAux = [...items];

        if (formData.chain !== "") {
            itemsAux = itemsAux.filter(item => item.chain == formData.chain);
        }
        if (formData.country !== "") {
            itemsAux = itemsAux.filter(item => item.country == formData.country);
        }
        if (formData.type !== "") {
            itemsAux = itemsAux.filter(item => item.type == formData.type);
        }
        if (formData.text !== "") {
            itemsAux = itemsAux.filter(item => item.organization.toLowerCase().includes(formData.text.toLowerCase())
                || item.firstname.toLowerCase().includes(formData.text.toLowerCase())
                || item.lastname.toLowerCase().includes(formData.text.toLowerCase()));
        }

        return itemsAux;
    }

    return (
        <Container color="colorgray" extras={"full-height"}>
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" color="color-primary-a50" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="color-white" textAlign="center">
                    <Container width="12" children={<TextLocalized children="msgSection1" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgNetworkingSubTitle" />} />

                    <ContainerFlex width="12" justify="center">
                        <Button onClick={() => history("/profile")} mx="16" ph="64" pv="16"
                            color="color-black" hoverColor="color-black-dark" radius="30px" fontSize="17px" fontWeight="normal"
                            children={<TextLocalized children="actionCompleteProfile" />} />
                    </ContainerFlex>
                </ContainerFlex>
            </Container>

            <Container width="12">
                <ContainerFlex width="12" fontColor="color-white" color="color-black" justify="center" align="center" pv="8">
                    <Container ph="32" fontColor="colorblack" fontSize="15px" fontWeight="bold" children={<TextLocalized children="msgFilterBy" />} />

                    <CustomInput onChange={onChange} inputmr="16" titleph="16" title={"msgOrgaOrName"} placeholder={dictionary["msgOrgaOrName"]} type="text" name="text" value={formData.text} />
                    <SelectInput onChange={onChange} inputmr="16" titleph="16" title={"msgType"} placeholder={dictionary["msgType"]} name="type" value={formData.type} options={types} color="color-white" />
                    <SelectInput onChange={onChange} inputmr="16" titleph="16" title={"msgValueChain"} placeholder={dictionary["msgValueChain"]} name="chain" value={formData.chain} options={chains} />
                    <SelectInput onChange={onChange} inputmr="16" titleph="16" title={"msgCountry"} placeholder={dictionary["msgCountry"]} name="country" value={formData.country} options={countries} />
                </ContainerFlex>
                <Container width="12" minheight="1px" color="colorgraydark" />
            </Container>

            <ContainerFlex width="12" justify="center">
                <NetworkingView items={filterItems()} />
            </ContainerFlex>
        </Container>
    );
};

export default NetworkingFilterView;