import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AreaInput, Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../components/util/HttpConnection';
import { LanguageContext } from '../../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../../components/providers/UserProvider';
import ThreadItem from './ThreadItem';

import img_home from '../../img/background-home.png';

const ThreadModal = ({ successCallback }) => {
    const [formData, setFormData] = useState({ title: "", text: "" });
    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.title && formData.text)
            HttpConnection(HttpOperations.threads, result => {
                dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                successCallback();
            }, error => console.log(error), HttpMethod.post, formData);
    }

    const close = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });

    return <Card onClick={e => e.stopPropagation()} shadowOn width="10" lg="4" px="16" color="color-white" radius="12px">
        <Container width="12" mb="32" textAlign="center" fontColor="color-blue-dark" fontWeight="300" fontSize="38px" children={<TextLocalized children="actionNewThread" />} />
        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgTitle"} placeholder={dictionary["msgTitle"]} type="text" name="title" value={formData.title} />
        <AreaInput onChange={onChange} inputmv="16" titleph="16" title={"msgWriteHere"} placeholder={dictionary["msgWriteHere"]} type="text" name="text" value={formData.text} />

        <Button onClick={sendRequest} px="16" mt="32" mb="16" textAlign="center" fontWeight="300" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" radius="30px" children={<TextLocalized children="actionSend" />} />
        <Button onClick={close} px="16" mt="16" mb="16" textAlign="center" fontWeight="300" color="color-black" hoverColor="color-black-dark" fontColor="color-white" radius="30px" children={<TextLocalized children="actionClose" />} />
    </Card>
}

const Forum = () => {
    const [threads, setThreads] = useState([]);
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    useEffect(() => { getItems() }, [])

    const getItems = () => HttpConnection(`${HttpOperations.threads}/state/1`, result => setThreads(result.item), error => console.log(error), HttpMethod.get);
    const showItem = (item) => history(`/forum/${item.id_thread}`);

    const showModal = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ThreadModal successCallback={getItems} /> } })
    }

    return (
        <Container width="12" justify="center" minheight="100vh" color="color-gray">
            <ContainerFlex width="12" justify="center" >
                <Container width="12" image={img_home}>
                    <ContainerFlex width="12" direction="column" align="center" color="color-primary-a50" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="color-white" textAlign="center">
                        <Container width="12" children={<TextLocalized children="msgSection3" />} />
                        <ContainerFlex width="10" xl="6" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgForumSubTitle" />} />

                        <ContainerFlex width="12" justify="center">
                            <Button onClick={showModal} mx="16" ph="64" pv="16"
                                color="color-black" hoverColor="color-black-dark" radius="30px" fontSize="17px" fontWeight="normal"
                                children={<TextLocalized children="actionNewThread" />} />

                            <Button onClick={() => window.open("http://exceliving.eu/contact/", "_blank")} mx="16" ph="64" pv="16"
                                color="color-white" hoverColor="color-gray" fontColor="color-primary" radius="30px" fontSize="17px" fontWeight="normal"
                                children={<TextLocalized children="msgHomeSection3Btn" />} />
                        </ContainerFlex>
                    </ContainerFlex>
                </Container>
                <ContainerFlex width="12" lg="8" mt="32" children={threads.map((item, index) => <ThreadItem key={index} item={item} showItem={showItem} />)} />
            </ContainerFlex>
        </Container>
    );
};

export default Forum;