import { mdiOfficeBuilding, mdiWeb, mdiEmail, mdiBriefcase, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { mdiFileDocumentEdit } from '@mdi/js';

import React, { Fragment, useContext } from 'react';
import { ContainerFlex, Card, Container, Button, TextLocalized } from '../../components/styled/Styled';
import { getStorageUser } from '../../components/util/UserUtil';
import { useNavigate } from 'react-router';
import { UserAction, UserContext } from '../../components/providers/UserProvider';
import { ModalViewDefault, ModalViewSure } from '../../components/util/ModalUtil';
import { HttpConnection, HttpMethod, HttpOperations } from '../../components/util/HttpConnection';

export const NetworkingItemInfoAccount = ({ icon, text, fontSize = "14px" }) => {
    return <ContainerFlex align="flex-center" width="12">
        <ContainerFlex align="center" children={<Icon path={icon} size={0.5} />} />
        <Container ml="8" fontSize={fontSize} children={text} />
    </ContainerFlex>
}

export const NetworkingItemInfo = ({ text, fontSize = "12px" }) => {
    return <Card ph="8" pv="4" mv="4" mr="8" border="solid" borderColor="color-black" borderWidth="1px" color="color-white" fontColor="color-black" fontWeight="600" fontSize={fontSize} children={text} />
}

export const NetworkingItemView = ({ item, showDetail, showEdit, showDelete }) => {

    return <ContainerFlex width="12" md="6" xl="6" px="16" minheight="100%">
        <Card flex shadowOn align="stretch" minheight="100%" width="12" color="color-white">

            <Card flex justify="center" align="center" fontColor="color-white" minwidth="50px" color="color-primary" radiustr="0px" radiusbr="0px">
                <Icon path={mdiOfficeBuilding} />
            </Card>

            <ContainerFlex width="wrap" px="16" direction="column" justify="space-between">
                <Container width="12">
                    <Container fontWeight="bold" fontSize="18px" children={`${item.firstname} ${item.lastname}`} />
                    <Container fontWeight="bold" fontSize="22px" children={item.organization} />
                    <Container fontSize="10px" fontColor="color-black" children={item.create_at} />

                    <ContainerFlex mv="8">
                        <NetworkingItemInfo text={item.type} />
                        <NetworkingItemInfo text={item.chain} />
                        <NetworkingItemInfo text={item.country} />
                    </ContainerFlex>

                    <NetworkingItemInfoAccount icon={mdiBriefcase} text={`${item.job}`} />
                    <NetworkingItemInfoAccount icon={mdiEmail} text={`${item.email}`} />
                    <NetworkingItemInfoAccount icon={mdiWeb} text={item.web} />

                    <ContainerFlex width="12" justify="flex-end">
                        {
                            getStorageUser().id_user == item.id_user ?
                                <Fragment>
                                    <Button flex align="center" onClick={showDelete} mv="8" ph="16" pv="8" color="color-red" hoverColor="color-red-dark" radius="10px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                                        <ContainerFlex mr="8" children={<Icon path={mdiDelete} size={1} />} />
                                        <TextLocalized children="actionDelete" />
                                    </Button>
                                    <Button flex align="center" ml="16" onClick={showEdit} mv="8" ph="16" pv="8" color="color-blue-light" hoverColor="color-primary-dark" radius="10px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                                        <ContainerFlex mr="8" children={<Icon path={mdiFileDocumentEdit} size={1} />} />
                                        <TextLocalized children="actionEdit" />
                                    </Button>
                                </Fragment>
                                : null
                        }
                        <Button onClick={showDetail} ml="16" mv="8" ph="16" pv="8" color="color-black" hoverColor="color-primary-dark" radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                            <TextLocalized children="actionDetails" />
                        </Button>
                    </ContainerFlex>
                </Container>
            </ContainerFlex>
        </Card>
    </ContainerFlex>
}

const NetworkingView = ({ items }) => {

    let { dispatch } = useContext(UserContext);
    let history = useNavigate();

    const showDetail = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault item={item} /> } })
    }

    const showDelete = () => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.user}`,
            () => dispatch({ action: UserAction.logout }),
            error => console.log(error), HttpMethod.delete);
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewSure actionPositive={actionPositive} actionDismiss={actionDismiss} /> } })
    }

    return (
        <ContainerFlex width="12" lg="10" px="32" align="stretch">
            {items.sort((a, b) => a.organization > b.organization ? 1 : -1).map((item, index) => <NetworkingItemView key={index} item={item} showDelete={showDelete} showDetail={() => showDetail(item)} showEdit={() => history("/profile")} />)}
        </ContainerFlex>
    );
};

export default NetworkingView;