import React, { useState, useEffect } from 'react';
import { Container, ContainerFlex, TextLocalized } from '../../components/styled/Styled';
import img_home from '../../img/background-home.png';
import { HttpConnection, HttpOperations } from '../../components/util/HttpConnection';
import TrainingResourceView from './TrainingResourceView';

const TrainingResourceFilterView = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        HttpConnection(HttpOperations.trainings,
            item => setItems(item.item),
            error => console.log(error));
    }, [])

    return (
        <Container color="colorgray" extras={"full-height"}>
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" color="color-primary-a50" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="color-white" textAlign="center">
                    <Container width="12" children={<TextLocalized children="msgSection2" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgTrainingSub" />} />
                </ContainerFlex>
            </Container>

            <ContainerFlex width="12" justify="center">
                <TrainingResourceView items={items} />
            </ContainerFlex>
        </Container>
    );
};

export default TrainingResourceFilterView;