import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { verifyEmailFormat } from '../components/util/UserUtil';

import logo_text from '../img/logo_white.png';
import logo_cenfim from '../img/logo-cenfim.svg';

import home from '../img/background-home.png';

const LoginView = () => {
    const [formData, setFormData] = useState({ email: "", password: "" })
    const { dictionary } = useContext(LanguageContext)
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.email !== "" && formData.password !== "") {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(HttpOperations.login,
                    result => {
                        history("./", { replace: true });
                        dispatch({ action: UserAction.login, data: result.item })
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } }),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <Container width="12" color="color-gray" image={home}>
            <ContainerFlex width="12" minheight="calc(100vh - 80px)" align="center" justify="center" color="color-primary-a50" >
                <Card shadowOn flex width="12" lg="8" align="center" color="color-primary" justify="center">
                    <ContainerFlex width="12" px="32" lg="5" align="center" direction="column" >
                        <img style={{ padding: "16px" }} alt="efic-logo" height="150px" src={logo_text} />
                        <Container ph="32" fontSize="15px" fontWeight="bold" fontColor="color-white" textAlign="center" children={<TextLocalized children="msgRegisterSubtitle" />} />
                    </ContainerFlex>

                    <Container width="12" lg="7" px="32" color="color-white">
                        <Container fontSize="40px" fontWeight="bold" children={<TextLocalized children="msgSection5" />} />
                        <Container mt="8" mb="32" width="8" height="4px" color="color-primary" />

                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />
                        <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={formData.password} />

                        <Button onClick={() => history("/forget")} mv="24" ph="8" textAlign="center" fontSize="12px" fontWeight="bold" children={<TextLocalized children="msgForget" />} />

                        <ButtonFlat action={sendRequest} label="actionLogin" fontColor="color-white" />

                        <Button onClick={() => history("/register")} mt="32" ph="8" textAlign="center" fontSize="12px" fontWeight="bold" children={<TextLocalized children="msgNotRegister" />} />

                    </Container>
                </Card>
            </ContainerFlex>
        </Container>
    );
};

export default LoginView;