import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { AreaInput, Button, Card, Container, ContainerFlex, CustomInput, SelectInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { getStorageUser, updateStorageUser, verifyEmailFormat } from '../components/util/UserUtil';

import logo_text from '../img/logo_white.png';

import home from '../img/background-home.png';
import { chains, countries, types, userDefault } from '../constants/model';

const ProfileView = () => {
    const [formData, setFormData] = useState({ ...userDefault });
    const [password, setPassword] = useState({ current_pass: "", new_pass: "" });

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        let user = getStorageUser();
        setFormData(user);
    }, [])

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onChangePass = (e) => setPassword({ ...password, [e.target.name]: e.target.value });


    const sendRequest = () => {
        if (formData.organization !== "" && formData.type !== "" && formData.chain !== "" && formData.web !== "" &&
            formData.country !== "" && formData.firstname !== "" && formData.lastname !== "" && formData.job !== "" &&
            formData.email !== "") {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(HttpOperations.user,
                    result => {
                        updateStorageUser(result.item);
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } })
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                    HttpMethod.put, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const sendPassowrd = () => {
        if (password.current_pass !== "" && password.new_pass !== "") {
            HttpConnection(`${HttpOperations.user}/password`,
                result => {
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } })
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.put, password);
        }
    }

    return (
        <Container width="12" color="color-gray" image={home}>
            <ContainerFlex width="12" minheight="calc(100vh - 80px)" align="center" justify="center" color="color-primary-a50" >
                <Card shadowOn flex width="12" lg="8" align="center" color="color-primary" justify="center" mv="50">
                    <ContainerFlex width="12" px="32" lg="5" align="center" direction="column" >
                        <img style={{ padding: "16px" }} alt="efic-logo" height="150px" src={logo_text} />
                        <Container display="none" ph="32" fontSize="15px" fontWeight="bold" fontColor="color-white" textAlign="center" children={<TextLocalized children="msgRegisterSubtitle" />} />
                    </ContainerFlex>

                    <Container width="12" lg="7" px="32" color="color-white">
                        <Container fontSize="40px" fontWeight="bold" children={<TextLocalized children="msgUserInfo" />} />
                        <Container mt="8" mb="32" width="8" height="4px" color="color-primary" />

                        <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgOrganization"} placeholder={dictionary["msgOrganization"]} type="text" name="organization" value={formData.organization} />
                        <SelectInput onChange={onChange} inputmv="16" titleph="16" title={"msgType"} placeholder={dictionary["msgType"]} name="type" value={formData.type} options={types} />
                        <SelectInput onChange={onChange} inputmv="8" titleph="16" title={"msgValueChain"} placeholder={dictionary["msgValueChain"]} name="chain" value={formData.chain} options={chains} />

                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgWeb"} placeholder={dictionary["msgWeb"]} type="text" name="web" value={formData.web} />
                        <SelectInput onChange={onChange} inputmv="8" titleph="16" title={"msgCountry"} placeholder={dictionary["msgCountry"]} name="country" value={formData.country} options={countries} />

                        <AreaInput onChange={onChange} inputmv="16" titleph="16" title={"msgServices"} placeholder={dictionary["msgWriteHere"]} type="text" name="services" value={formData.services} />
                        <AreaInput onChange={onChange} inputmv="16" titleph="16" title={"msgAreas"} placeholder={dictionary["msgWriteHere"]} type="text" name="areas" value={formData.areas} />
                        <AreaInput onChange={onChange} inputmv="16" titleph="16" title={"msgNeeds"} placeholder={dictionary["msgWriteHere"]} type="text" name="needs" value={formData.needs} />
                        <AreaInput onChange={onChange} inputmv="16" titleph="16" title={"msgCollaborations"} placeholder={dictionary["msgWriteHere"]} type="text" name="collaboration" value={formData.collaboration} />

                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgFirstname"} placeholder={dictionary["msgFirstname"]} type="text" name="firstname" value={formData.firstname} />
                        <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgLastname"} placeholder={dictionary["msgLastname"]} type="text" name="lastname" value={formData.lastname} />
                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgJobTitle"} placeholder={dictionary["msgJobTitle"]} type="text" name="job" value={formData.job} />
                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />

                        <ButtonFlat mt="48" action={sendRequest} label="actionSave" fontColor="color-white" />

                        <Container fontSize="40px" mt="30" fontWeight="bold" children={<TextLocalized children="msgUserCredentials" />} />
                        <Container mt="8" mb="32" width="8" height="4px" color="color-primary" />

                        <CustomInput onChange={onChangePass} inputmv="8" titleph="16" title={"msgCurrentPassword"} placeholder={dictionary["msgCurrentPassword"]} type="password" name="current_pass" value={password.current_pass} />
                        <CustomInput onChange={onChangePass} inputmv="8" titleph="16" title={"msgNewPassword"} placeholder={dictionary["msgNewPassword"]} type="password" name="new_pass" value={password.new_pass} />

                        <ButtonFlat mt="48" action={sendPassowrd} label="actionSave" fontColor="color-white" />
                    </Container>
                </Card>
            </ContainerFlex>
        </Container>
    );
};

export default ProfileView;