import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Fragment, useContext } from 'react';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import { Container } from './components/styled/Styled';
import Header from './components/Header';
import { Footer } from './components/Footer';
import LoginView from './views/LoginView';
import RegisterView from './views/RegisterView';
import HomeView from './views/HomeView';
import Forum from './views/forum/Forum';
import ThreadDetail from './views/forum/ThreadDetail';
import TrainingResourceFilterView from './views/training/TrainingResourceFilterView';
import NetworkingFilterView from './views/networking/NetworkingFilterView';
import ProfileView from './views/ProfileView';
import ForgetView from './views/ForgetView';

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" height="100vh" overflowY="auto" color="color-white">
      <Router basename="">
        <Fragment>
          <Header />
          <Container width="12" minheight="calc(100vh - 80px)" color="color-gray" >
            {
              state.login ?
                <Routes>
                  <Route exact={true} path="/" element={<HomeView />} />
                  <Route exact={true} path="/networking" element={<NetworkingFilterView />} />
                  <Route exact={true} path="/training" element={<TrainingResourceFilterView />} />
                  <Route exact={true} path="/forum" element={<Forum />} />
                  <Route exact={true} path="/forum/:id" element={<ThreadDetail />} />
                  <Route exact={true} path="/profile" element={<ProfileView />} />
                  <Route exact={true} path="*" element={<HomeView />} />
                </Routes>
                :
                <Routes>
                  <Route exact={true} path="/" element={<HomeView />} />
                  <Route exact={true} path="/forget" element={<ForgetView />} />
                  <Route exact={true} path="/register" element={<RegisterView />} />
                  <Route exact={true} path="*" element={<LoginView />} />
                </Routes>
            }
          </Container>
          <Footer />
        </Fragment>
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />


export default App;
