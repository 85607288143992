import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { checkValues, verifyEmailFormat } from '../components/util/UserUtil';

import logo_text from '../img/logo_white.png';

import home from '../img/background-home.png';

const ForgetView = () => {
    const [formData, setFormData] = useState({ email: "", password: "", code: "" })
    const [requestedCode, setRequestedCode] = useState(false);

    const { dictionary } = useContext(LanguageContext)
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.email !== "") {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(`${HttpOperations.user}/code`,
                    result => setRequestedCode(true),
                    error => setRequestedCode(true),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const updatePassword = () => {
        if (checkValues(formData)) {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(`${HttpOperations.user}/reset`,
                    result => {
                        history("/login", { replace: true });
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                    },
                    error => {
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorRecovery" } });
                    },
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <Container width="12" color="color-gray" image={home}>
            <ContainerFlex width="12" minheight="calc(100vh - 80px)" align="center" justify="center" color="color-primary-a50" >
                <Card shadowOn flex width="12" lg="8" align="center" color="color-primary" justify="center">
                    <ContainerFlex width="12" px="32" lg="5" align="center" direction="column" >
                        <img style={{ padding: "16px" }} alt="efic-logo" height="150px" src={logo_text} />
                        <Container ph="32" fontSize="15px" fontWeight="bold" fontColor="color-white" textAlign="center" children={<TextLocalized children="msgRegisterSubtitle" />} />
                    </ContainerFlex>

                    <Container width="12" lg="7" px="32" color="color-white">
                        <Container fontSize="40px" fontWeight="bold" children={<TextLocalized children="msgRecoveryPassword" />} />
                        <Container mt="8" mb="16" width="8" height="4px" color="color-primary" />
                        <Container mb="32" fontSize="14px" fontWeight="400" children={<TextLocalized children="msgRecoveryText" />} />


                        <CustomInput disabled={requestedCode} onChange={onChange} inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />

                        {
                            requestedCode ?
                                <Fragment>
                                    <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgCode"} placeholder={dictionary["msgCode"]} type="text" name="code" value={formData.code} />
                                    <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgNewPassword"} placeholder={dictionary["msgNewPassword"]} type="password" name="password" value={formData.password} />
                                    <ButtonFlat mt="48" action={updatePassword} label="msgRecoveryPassword" textTransform="uppercase" fontColor="color-white" />
                                </Fragment>
                                :
                                <ButtonFlat mt="48" action={sendRequest} label="actionRequestCode" textTransform="uppercase" fontColor="color-white" />
                        }
                        <ButtonFlat mt="16" action={() => history(-1)} border="solid" borderWidth="1px" borderColor="color-black" hoverColor="color-black-dark" color="color-white" label="actionBack" fontColor="color-black" />

                    </Container>
                </Card>
            </ContainerFlex>
        </Container>
    );
};

export default ForgetView;