import React, { useContext, useState } from 'react';
import { ContainerFlex, Card, Container, Button, TextLocalized } from '../../components/styled/Styled';
import styled from 'styled-components';

import { docURL, imageURL } from '../../components/util/HttpConnection';

import { UserContext } from '../../components/providers/UserProvider';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import { NetworkingItemInfo } from '../networking/NetworkingView';

const RoundLeftImg = styled.img`
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
`;

export const TrainingResourceItemView = ({ item }) => {
    const [showDetails, setShowDetails] = useState(false);

    const history = useNavigate();
    const { state } = useContext(UserContext);

    const performAction = (url) => {
        if (state.login) {
            window.open(url, "_blank")
        } else {
            history("/login");
        }
    }

    const getDate = () => {
        let dateString = format(new Date(item.create_at), "dd/MM/yyyy");
        if (item.start_at) {
            dateString += ` - ${format(new Date(item.start_at), "HH:mm")} to ${format(new Date(item.end_at), "HH:mm")}`;
        }
        return dateString;
    }

    return <ContainerFlex width="12" lg="6" px="16" minheight="100%">
        <Card flex shadowOn align="flex-start" minheight="100%" direction="column" width="12" color="color-white">

            <Container width="12">
                <RoundLeftImg src={`${imageURL}${item.image}`} width="100%" height="220px" style={{ objectFit: "cover" }} />
            </Container>

            <ContainerFlex width="12" ph="16" direction="column" expand justify="space-between">
                <Container>
                    <Container fontWeight="bold" fontSize="16px" mt="16" children={item.title} />
                    <Container mt="8" fontSize="12px" fontColor="colorgrayalpha" children={getDate()} />

                    <ContainerFlex mt="8">
                        {item.target && item.type ? <NetworkingItemInfo text={`${item.target?.name} / ${item.type?.name}`} /> : null}
                        {item.speaker ? <NetworkingItemInfo text={item.speaker} /> : null}
                        {item.company ? <NetworkingItemInfo text={item.company} /> : null}
                    </ContainerFlex>

                    {
                        showDetails ?
                            <Container fontSize="14px" mt="16" children={<div style={{ whiteSpace: "pre-line" }} children={item.description} />} />
                            : null
                    }
                </Container>

                <ContainerFlex mv="16" align="flex-end">
                    <Button flex mr="16" color="color-primary" hoverColor="color-primary-dark" fontSize="16px" pv="8" ph="32" onClick={() => setShowDetails(!showDetails)} align="center" fontColor="color-white" textTransform="uppercase">
                        <TextLocalized children="actionDetails" />
                    </Button>
                    {
                        item.registration_url ?
                            <Button flex color="color-black" hoverColor="color-black-dark" fontSize="16px" pv="8" ph="32" onClick={() => performAction(item.registration_url)} align="center" fontColor="color-white" textTransform="uppercase">
                                <TextLocalized children={"actionJoin"} />
                            </Button>
                            : item.url ?
                                <Button flex color="color-black" hoverColor="color-black-dark" fontSize="16px" pv="8" ph="32" onClick={() => performAction(item.url)} align="center" fontColor="color-white" textTransform="uppercase">
                                    <TextLocalized children={"actionVideo"} />
                                </Button>
                                : null
                    }
                    {
                        item.doc_url && item.url ?
                            <Button flex color="color-black" hoverColor="color-black-dark" ml="16" fontSize="16px" pv="8" ph="32" onClick={() => performAction(`${docURL}${item.doc_url}`)} align="center" fontColor="color-white" textTransform="uppercase">
                                <TextLocalized children={"actionDoc"} />
                            </Button>
                            : <Button flex color="color-black" hoverColor="color-black-dark" fontSize="16px" pv="8" ph="32" onClick={() => performAction(`${docURL}${item.doc_url}`)} align="center" fontColor="color-white" textTransform="uppercase">
                                <TextLocalized children={"actionEbook"} />
                            </Button>
                    }
                </ContainerFlex>
            </ContainerFlex>
        </Card>
    </ContainerFlex>
}

const TrainingResourceView = ({ items }) => {
    return (
        <ContainerFlex width="12" lg="10" px="32">
            {items.map((item, index) => <TrainingResourceItemView key={index} item={item} />)}
        </ContainerFlex>
    );
};

export default TrainingResourceView;