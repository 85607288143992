import Icon from '@mdi/react';
import { mdiAccount, mdiClockOutline, mdiCommentOutline } from '@mdi/js';

import React from 'react';
import { Button, Card, Container, ContainerFlex } from '../../components/styled/Styled';
import { format } from 'date-fns';

const ThreadItem = ({ item, showItem }) => {
    return <Container width="12" lg="6" px="8">
        <Button onClick={() => showItem(item)} shadowOn flex width="12" align="stretch" border="solid" borderColor="colorbluelight" borderWidth="1px" color="color-white">
            <Container px="8" width="wrap">
                <Container fontSize="20px" fontWeight="500" children={item.title} />
                <ContainerFlex mt="8" align="stretch">
                    <Card px="8" flex align="center" color="color-black" fontColor="color-white">
                        <Icon style={{ margin: "0 8px 0 0" }} path={mdiAccount} size={"12px"} />
                        <Container fontSize="12px" fontWeight="100" children={`${item.owner.firstname} ${item.owner.lastname}`} />
                    </Card>
                    <Card mh="8" px="8" flex align="center" color="color-black" fontColor="color-white">
                        <Icon style={{ margin: "0 8px" }} path={mdiClockOutline} size={"12px"} />
                        <Container fontSize="12px" fontWeight="100" children={format(new Date(item.create_at), "dd/MM/yyyy")} />
                    </Card>
                </ContainerFlex>
            </Container>
            <ContainerFlex color="color-primary" fontColor="color-white" px="8" direction="column" align="center" justify="center">
                <Container fontWeight="bold" fontSize="18px" children={item.num_messages} />
                <Icon style={{ margin: "0 8px" }} path={mdiCommentOutline} size={0.5} />
            </ContainerFlex>
        </Button>
    </Container>;
};

export default ThreadItem;