const es = {
    actionRegister: "REGISTER",
    actionLogin: "LOGIN",
    actionContinue: "CONTINUE",
    actionConfirmCon: "CONFIRM & CONTINUE",
    actionUpdate: "UPDATE",
    actionEdit: "COMPLETE YOUR PROFILE",
    actionDelete: "DELETE",
    actionBack: "COME BACK",
    actionComment: "COMMENT",
    actionSave: "SAVE",
    actionCreate: "CREATE",
    actionYes: "YES",
    actionNo: "No",
    actionAdd: "ADD",
    actionCancel: "CANCEL",
    actionAccept: "ACCEPT",
    actionSelect: "SELECT",
    actionDetails: "DETAILS",
    actionJoin: "JOIN",
    actionVideo: "VIDEO",
    actionRequestCode: "REQUEST CODE",
    actionDoc: "PRESENTATION",
    actionEbook: "E-BOOK",

    actionNew: "NEW ENTRY",
    actionExit: "Logout",

    actionShow: "Show",
    actionClose: "CLOSE",

    actionDownload: "DOWNLOAD",

    actionNewThread: "ADD NEW THREAD",
    actionSend: "SEND",
    actionCompleteProfile: "COMPLETE YOUR PROFILE",

    msgRegisterSubtitle: "Register and contact entities with common challenges, needs and complementarities.",
    msgRegisterMandatory: "All fields are required, please complete all before submitting your registration",
    msgUserInfo: "User Information",
    msgUserCredentials: "User Credentials",

    msgOrgaOrName: "Organization or name",
    msgFirstname: "First name",
    msgLastname: "Last name",
    msgJobTitle: "Job title",
    msgOrganization: "Organization",
    msgType: "Type",
    msgType1: "SME",
    msgType2: "Big company",
    msgType3: "R&D support organization",
    msgType4: "Cluster or other business association",
    msgType5: "Other",

    msgValueChain: "Value chain",
    msgValueChain1: "Construction building materials",
    msgValueChain2: "Furniture and lighting",
    msgValueChain3: "Home automation",
    msgValueChain4: "Welfare technology",
    msgValueChain5: "Other",

    msgWeb: "Web",
    msgCountry: "Country",

    msgEmail: "Email",
    msgPassword: "Password",
    msgCurrentPassword: "Current password",
    msgNewPassword: "New Password",
    msgCode: "Code",

    msgRecoveryPassword: "Reset Password",
    msgForget: "Have you forgotten your password?",
    msgRecoveryText: "If the email provided belongs to our organization, you will receive an email with your authentication code and you will be able to reset your password.",

    msgSection1: "Networking",
    msgSection2: "Training",
    msgSection3: "Forum",
    msgSection4: "Profile",
    msgSection5: "Login",

    msgSearch: "Search field",

    msgNotRegister: "Not registered yet? Create an account here",
    msgAlreadyRegistered: "Are you registered? Login here", 

    msgHomeTitleOne: "EXCELIVING HELPDESK",
    msgHomeSubTitle: "Networking platform between companies and clusters of the habitat value chain towards healthy, age-friendly and sustainable living environments.",

    msgHomeSection1Sub: "Register and contact entities with common challenges, needs and complementarities.",
    msgHomeSection2Sub: "Register and have access to the training resources of EXCELIVING.",

    msgHomeSection1Btn: "ALL ENTITIES",
    msgHomeSection2Btn: "ALL TRAINING RESOURCES",
    msgHomeSection3Btn: "CONTACT US",

    msgHomeSection3: "Who is behind the EXCELIVING HELPDESK?",
    msgNetworkingSubTitle: "Search and contact entities of the habitat value chain with common challenges, needs and complementarities. Complete and update your data in your \"Profile\".", 
    msgForumSubTitle: "Open a discussions and launch a question and our team of advisors will answer you shortly. If you want to make a confidential question, contact us.",
    msgTrainingSub: "Check out our collection of training resources.",

    msgTitle: "Title",
    msgWriteHere: "Write here...",

    msgServices: "Products/services offered",
    msgAreas: "Areas of expertise",
    msgNeeds: "Needs and challenges",
    msgCollaborations: "Collaboration sought",

    msgFilterBy: "Filter by",



    /* MODAL */
    msgAreSure: "Are you sure?",

    msgSuccess: "Data update succesfully",
    msgDeleteSuccess: "Data delete succesfully",

    errorOblData: "Fill all mandatory fields",
    errorEmailFormat: "Email format error",
    errorUserIncorrect: "Email/password incorrect",
    errorSend: "Communication error please wait and retry later",
    errorLength: "The number of characters has been exceded",
    errorRecovery: "Recovery password error, please check code provided",


    msgFooterContact: "CONTACT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",

    msgFooterGr: "This project has received funding from the European Union’s COSME programme under Grant Agreement no. 101037887. The content of this website represents the views of the author only and is his/her sole responsibility; it cannot be considered to reflect the views of the European Commission and/or the European Innovation Council and SMEs Executive Agency (EISMEA) or any other body of European Union. The European Commission and the Agency do not accept any responsibility for the use that may be made of the information it contains.",
    msgCopyright: "Copyright&copy;2022 CENFIM. All rights reserved",

    msgFooterLegal: "Legal notice",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Cookie Policy",
    msgFooterCondic: "Condiciones generales del servicio",
    msgFooterCalidad: "Política de calidad",

     /* COUNTRIES */
     msgAfghanistan: "Afghanistan",
     msgAlbania: "Albania",
     msgAlgeria: "Algeria",
     msgAmericanSamoa: "American Samoa",
     msgAndorra: "Andorra",
     msgAngola: "Angola",
     msgAnguilla: "Anguilla",
     msgAntarctica: "Antarctica",
     msgAntiguaandBarbuda: "Antigua and Barbuda",
     msgArgentina: "Argentina",
     msgArmenia: "Armenia",
     msgAruba: "Aruba",
     msgAustralia: "Australia",
     msgAustria: "Austria",
     msgAzerbaijan: "Azerbaijan",
     msgBahamas: "Bahamas",
     msgBahrain: "Bahrain",
     msgBangladesh: "Bangladesh",
     msgBarbados: "Barbados",
     msgBelarus: "Belarus",
     msgBelgium: "Belgium",
     msgBelize: "Belize",
     msgBenin: "Benin",
     msgBermuda: "Bermuda",
     msgBhutan: "Bhutan",
     msgBolivia: "Bolivia",
     msgBosniaHerzegovina: "Bosnia and Herzegovina",
     msgBotswana: "Botswana",
     msgBouvetIsland: "Bouvet Island",
     msgBrazil: "Brazil",
     msgBritishIndian: "British Indian Ocean Territory",
     msgBrunei: "Brunei",
     msgBulgaria: "Bulgaria",
     msgBurkinaFaso: "Burkina Faso",
     msgBurundi: "Burundi",
     msgCambodia: "Cambodia",
     msgCameroon: "Cameroon",
     msgCanada: "Canada",
     msgCapeVerde: "Cape Verde",
     msgCaymanIslands: "Cayman Islands",
     msgCentralAfrican: "Central African Republic",
     msgChad: "Chad",
     msgChile: "Chile",
     msgChina: "China",
     msgChristmasIsland: "Christmas Island",
     msgCocosIslands: "Cocos (Keeling) Islands",
     msgColombia: "Colombia",
     msgComoros: "Comoros",
     msgCongo: "Congo",
     msgRepublicCongo: "The Democratic Republic of Congo",
     msgCookIslands: "Cook Islands",
     msgCostaRica: "Costa Rica",
     msgIvoryCoast: "Ivory Coast",
     msgCroatia: "Croatia",
     msgCuba: "Cuba",
     msgCyprus: "Cyprus",
     msgCzechRepublic: "Czech Republic",
     msgDenmark: "Denmark",
     msgDjibouti: "Djibouti",
     msgDominica: "Dominica",
     msgDominicanRepublic: "Dominican Republic",
     msgEastTimor: "East Timor",
     msgEcuador: "Ecuador",
     msgEgypt: "Egypt",
     msgEngland: "England",
     msgElSalvador: "El Salvador",
     msgEquatorialGuinea: "Equatorial Guinea",
     msgEritrea: "Eritrea",
     msgEstonia: "Estonia",
     msgEthiopia: "Ethiopia",
     msgFalklandIslands: "Falkland Islands",
     msgFaroeIslands: "Faroe Islands",
     msgFijiIslands: "Fiji Islands",
     msgFinland: "Finland",
     msgFrance: "France",
     msgFrenchGuiana: "French Guiana",
     msgFrenchPolynesia: "French Polynesia",
     msgFrenchSouthern: "French Southern territories",
     msgGabon: "Gabon",
     msgGambia: "Gambia",
     msgGeorgia: "Georgia",
     msgGermany: "Germany",
     msgGhana: "Ghana",
     msgGibraltar: "Gibraltar",
     msgGreece: "Greece",
     msgGreenland: "Greenland",
     msgGrenada: "Grenada",
     msgGuadeloupe: "Guadeloupe",
     msgGuam: "Guam",
     msgGuatemala: "Guatemala",
     msgGuernsey: "Guernsey",
     msgGuinea: "Guinea",
     msgGuineaBissau: "Guinea-Bissau",
     msgGuyana: "Guyana",
     msgHaiti: "Haiti",
     msgHeardMcDonald: "Heard Island and McDonald Islands",
     msgHolySee: "Holy See (Vatican City State)",
     msgHonduras: "Honduras",
     msgHongKong: "Hong Kong",
     msgHungary: "Hungary",
     msgIceland: "Iceland",
     msgIndia: "India",
     msgIndonesia: "Indonesia",
     msgIran: "Iran",
     msgIraq: "Iraq",
     msgIreland: "Ireland",
     msgIsrael: "Israel",
     msgIsleMan: "Isle of Man",
     msgItaly: "Italy",
     msgJamaica: "Jamaica",
     msgJapan: "Japan",
     msgJersey: "Jersey",
     msgJordan: "Jordan",
     msgKazakhstan: "Kazakhstan",
     msgKenya: "Kenya",
     msgKiribati: "Kiribati",
     msgKuwait: "Kuwait",
     msgKyrgyzstan: "Kyrgyzstan",
     msgLaos: "Laos",
     msgLatvia: "Latvia",
     msgLebanon: "Lebanon",
     msgLesotho: "Lesotho",
     msgLiberia: "Liberia",
     msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
     msgLiechtenstein: "Liechtenstein",
     msgLithuania: "Lithuania",
     msgLuxembourg: "Luxembourg",
     msgMacao: "Macao",
     msgNorthMacedonia: "North Macedonia",
     msgMadagascar: "Madagascar",
     msgMalawi: "Malawi",
     msgMalaysia: "Malaysia",
     msgMaldives: "Maldives",
     msgMali: "Mali",
     msgMalta: "Malta",
     msgMarshallIslands: "Marshall Islands",
     msgMartinique: "Martinique",
     msgMauritania: "Mauritania",
     msgMauritius: "Mauritius",
     msgMayotte: "Mayotte",
     msgMexico: "Mexico",
     msgMicronesia: "Micronesia, Federated States of",
     msgMoldova: "Moldova",
     msgMonaco: "Monaco",
     msgMongolia: "Mongolia",
     msgMontserrat: "Montserrat",
     msgMontenegro: "Montenegro",
     msgMorocco: "Morocco",
     msgMozambique: "Mozambique",
     msgMyanmar: "Myanmar",
     msgNamibia: "Namibia",
     msgNauru: "Nauru",
     msgNepal: "Nepal",
     msgNetherlands: "Netherlands",
     msgNetherlandsAntilles: "Netherlands Antilles",
     msgNewCaledonia: "New Caledonia",
     msgNewZealand: "New Zealand",
     msgNicaragua: "Nicaragua",
     msgNiger: "Niger",
     msgNigeria: "Nigeria",
     msgNiue: "Niue",
     msgNorfolkIsland: "Norfolk Island",
     msgNorthKorea: "North Korea",
     msgNorthernIreland: "Northern Ireland",
     msgNorthernMariana: "Northern Mariana Islands",
     msgNorway: "Norway",
     msgOman: "Oman",
     msgPakistan: "Pakistan",
     msgPalau: "Palau",
     msgPalestine: "Palestine",
     msgPanama: "Panama",
     msgPapua: "Papua New Guinea",
     msgParaguay: "Paraguay",
     msgPeru: "Peru",
     msgPhilippines: "Philippines",
     msgPitcairn: "Pitcairn",
     msgPoland: "Poland",
     msgPortugal: "Portugal",
     msgPuertoRico: "Puerto Rico",
     msgQatar: "Qatar",
     msgReunion: "Reunion",
     msgRomania: "Romania",
     msgRussianFederation: "Russian Federation",
     msgRwanda: "Rwanda",
     msgSaintHelena: "Saint Helena",
     msgSaintKitts: "Saint Kitts and Nevis",
     msgSaintLucia: "Saint Lucia",
     msgSaintPierre: "Saint Pierre and Miquelon",
     msgSaintVincent: "Saint Vincent and the Grenadines",
     msgSamoa: "Samoa",
     msgSanMarino: "San Marino",
     msgSaoTome: "Sao Tome and Principe",
     msgSaudiArabia: "Saudi Arabia",
     msgScotland: "Scotland",
     msgSenegal: "Senegal",
     msgSerbia: "Serbia",
     msgSeychelles: "Seychelles",
     msgSierraLeone: "Sierra Leone",
     msgSingapore: "Singapore",
     msgSlovakia: "Slovakia",
     msgSlovenia: "Slovenia",
     msgSolomonIslands: "Solomon Islands",
     msgSomalia: "Somalia",
     msgSouthAfrica: "South Africa",
     msgSouthSandwich: "South Georgia and the South Sandwich Islands",
     msgSouthKorea: "South Korea",
     msgSouthSudan: "South Sudan",
     msgSpain: "Spain",
     msgSriLanka: "SriLanka",
     msgSudan: "Sudan",
     msgSuriname: "Suriname",
     msgSvalbardJan: "Svalbard and Jan Mayen",
     msgSwaziland: "Swaziland",
     msgSweden: "Sweden",
     msgSwitzerland: "Switzerland",
     msgSyria: "Syria",
     msgTajikistan: "Tajikistan",
     msgTanzania: "Tanzania",
     msgThailand: "Thailand",
     msgTimorLeste: "Timor-Leste",
     msgTogo: "Togo",
     msgTokelau: "Tokelau",
     msgTonga: "Tonga",
     msgTrinidadTobago: "Trinidad and Tobago",
     msgTunisia: "Tunisia",
     msgTurkey: "Turkey",
     msgTurkmenistan: "Turkmenistan",
     msgTurksCaicos: "Turks and Caicos Islands",
     msgTuvalu: "Tuvalu",
     msgUganda: "Uganda",
     msgUkraine: "Ukraine",
     msgUnitedArabEmirates: "United Arab Emirates",
     msgUnitedKingdom: "United Kingdom",
     msgUnitedStates: "United States",
     msgUnitedStatesMinor: "United States Minor Outlying Islands",
     msgUruguay: "Uruguay",
     msgUzbekistan: "Uzbekistan",
     msgVanuatu: "Vanuatu",
     msgVenezuela: "Venezuela",
     msgVietnam: "Vietnam",
     msgVirginIslandsBritish: "Virgin Islands, British",
     msgVirginIslandsUS: "Virgin Islands, U.S.",
     msgWales: "Wales",
     msgWallisFutuna: "Wallis and Futuna",
     msgWesternSahara: "Western Sahara",
     msgYemen: "Yemen",
     msgZambia: "Zambia",
     msgZimbabwe: "Zimbabwe",
 
}

export default es;