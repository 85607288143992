import { getStorageUser, getUserToken } from '../util/UserUtil';

export const HttpOperations = {
    login: "auth",
    user: "user",

    trainings: "trainings",
    networkings: "networkings",
    threads: "threads",
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE",
    put: "PUT"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {
    let token = getUserToken();
    let user = getStorageUser();
    console.log(user)

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        error(request.status);
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

const debugMode = false;
//movil: 172.20.10.8
//wifi: 192.168.5.39

export const serverUrlEndpoint = debugMode ? "http://192.168.5.38/cenfim/exceliving-api/public/api/" : "https://helpdesk.ambitcluster.org/exceliving-api/public/api/";
export const imageURL = debugMode ? "http://192.168.5.38/cenfim/exceliving-api/public/images/" : "https://helpdesk.ambitcluster.org/exceliving-api/public/images/";
export const docURL = debugMode ? "http://192.168.5.38/cenfim/exceliving-api/public/docs/" : "https://helpdesk.ambitcluster.org/exceliving-api/public/docs/";