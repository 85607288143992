import { mdiAccount, mdiEmail, mdiAccountSchool, mdiOfficeBuilding, mdiWeb, mdiBriefcase } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext } from 'react';
import { NetworkingItemInfo, NetworkingItemInfoAccount } from '../../views/networking/NetworkingView';
import { UserAction, UserContext } from '../providers/UserProvider';
import { Container, ContainerFlex, Button, Card, TextLocalized } from '../styled/Styled';

const NetworkingInfoOrganization = ({ label, item }) => {
    return item ?
        <Container width="6" ph="8">
            <Card width="12" mv="16" border="solid" borderWidth="1px" borderColor="color-black" >
                <Card width="12" px="8" color="color-black" radiusbl="0px" radiusbr="0px" fontColor="color-white" fontSize="14px" fontWeight="bold" children={<TextLocalized children={label} />} />
                <Container px="8" children={item} />
            </Card>
        </Container>
        : null
}

export const ModalViewSure = ({ actionPositive, actionDismiss }) => <Card width="auto" color="color-white">
    <Container px="16" fontWeight="bold" fontSize="20px" textAlign="center" children={<TextLocalized children="msgAreSure" />} />
    <ContainerFlex width="auto" pv="8">
        <Button color="color-primary" hoverColor="color-primary-dark" ph="64" pv="8" mh="8" fontColor="color-white" fontWeight="bold" onClick={() => actionPositive()} children={<TextLocalized children="actionYes" />} />
        <Button color="color-red" hoverColor="color-red-dark" ph="64" pv="8" mh="8" fontColor="color-white" fontWeight="bold" onClick={() => actionDismiss()} children={<TextLocalized children="actionNo" />} />
    </ContainerFlex>
</Card>

export const ModalViewDefault = ({ item }) => <Card flex shadowOn width="10" color="color-white">

    <Card flex pv="16" justify="center" align="center" fontColor="color-white" width="12" color="color-primary" radiusbr="0px" radiusbl="0px">
        <Icon path={mdiOfficeBuilding} size={2} />
    </Card>

    <ContainerFlex width="wrap" pv="16" direction="column" justify="space-between">
        <Container width="12">
            <Container width="12" ph="16">
                <Container fontWeight="bold" fontSize="18px" children={`${item.firstname} ${item.lastname}`} />
                <Container fontWeight="bold" fontSize="32px" children={item.organization} />
                <Container fontSize="14px" fontColor="color-black" children={item.create_at} />

                <ContainerFlex mv="8">
                    <NetworkingItemInfo text={item.type} fontSize="18px" />
                    <NetworkingItemInfo text={item.chain} fontSize="18px" />
                    <NetworkingItemInfo text={item.country} fontSize="18px" />
                </ContainerFlex>

                <NetworkingItemInfoAccount icon={mdiWeb} text={item.web} fontSize="18px" />
                <NetworkingItemInfoAccount icon={mdiBriefcase} text={`${item.job}`} fontSize="18px" />
                <NetworkingItemInfoAccount icon={mdiEmail} text={`${item.email}`} fontSize="18px" />
            </Container>

            <ContainerFlex width="12" ph="8">
                <NetworkingInfoOrganization item={item.services} label="msgServices" />
                <NetworkingInfoOrganization item={item.areas} label="msgAreas" />
                <NetworkingInfoOrganization item={item.needs} label="msgNeeds" />
                <NetworkingInfoOrganization item={item.collaboration} label="msgCollaborations" />
            </ContainerFlex>

            <ContainerFlex width="12" ph="16" justify="flex-end">
                <Button mv="8" ph="16" pv="8" color="color-black" hoverColor="color-primary-dark" radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                    <TextLocalized children="actionClose" />
                </Button>
            </ContainerFlex>
        </Container>
    </ContainerFlex>
</Card>

const ModalView = ({ children }) => {
    const { state, dispatch } = useContext(UserContext);
    const _hiddeModal = () => dispatch({ action: UserAction.showModal, data: { ...state.notification, show: false } });

    return <ContainerFlex onClick={_hiddeModal} justify="center" align="center" position="fixed" top="0" elevation="100"
        width="12" height="100%" maxheight="100%" overflowY="scroll" color="color-gray-a50"
        children={children} />;
};

export default ModalView;