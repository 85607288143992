import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, SelectInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { checkValues, verifyEmailFormat } from '../components/util/UserUtil';

import logo_text from '../img/logo_white.png';

import home from '../img/background-home.png';
import { chains, countries, types, userDefault } from '../constants/model';

const RegisterView = () => {
    const [formData, setFormData] = useState({ ...userDefault })
    const { dictionary } = useContext(LanguageContext)
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (checkValues(formData, ['services', 'areas', 'needs', 'collaboration'])) {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(HttpOperations.user,
                    result => {
                        history("./", { replace: true });
                        dispatch({ action: UserAction.login, data: result.item })
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } }),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "msgRegisterMandatory" } })
        }
    }

    return (
        <Container width="12" color="color-gray" image={home}>
            <ContainerFlex width="12" minheight="calc(100vh - 80px)" align="center" justify="center" color="color-primary-a50" >
                <Card shadowOn flex width="12" lg="8" align="center" color="color-primary" justify="center" mv="50">
                    <ContainerFlex width="12" px="32" lg="5" align="center" direction="column" >
                        <img style={{ padding: "16px" }} alt="efic-logo" height="150px" src={logo_text} />
                        <Container ph="32" fontSize="15px" fontWeight="bold" fontColor="color-white" textAlign="center" children={<TextLocalized children="msgRegisterSubtitle" />} />
                    </ContainerFlex>

                    <Container width="12" lg="7" px="32" color="color-white">
                        <Container fontSize="40px" fontWeight="bold" children={<TextLocalized children="msgUserInfo" />} />
                        <Container mt="8" mb="16" width="8" height="4px" color="color-primary" />

                        <Container mb="32" fontSize="14px" fontWeight="400" children={<TextLocalized children="msgRegisterMandatory" />} />

                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgFirstname"} placeholder={dictionary["msgFirstname"]} type="text" name="firstname" value={formData.firstname} />
                        <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgLastname"} placeholder={dictionary["msgLastname"]} type="text" name="lastname" value={formData.lastname} />
                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgJobTitle"} placeholder={dictionary["msgJobTitle"]} type="text" name="job" value={formData.job} />
                        <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgOrganization"} placeholder={dictionary["msgOrganization"]} type="text" name="organization" value={formData.organization} />

                        <SelectInput onChange={onChange} inputmv="16" titleph="16" title={"msgType"} placeholder={dictionary["msgType"]} name="type" value={formData.type} options={types} />
                        <SelectInput onChange={onChange} inputmv="8" titleph="16" title={"msgValueChain"} placeholder={dictionary["msgValueChain"]} name="chain" value={formData.chain} options={chains} />
                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgWeb"} placeholder={dictionary["msgWeb"]} type="text" name="web" value={formData.web} />
                        <SelectInput onChange={onChange} inputmv="8" titleph="16" title={"msgCountry"} placeholder={dictionary["msgCountry"]} name="country" value={formData.country} options={countries} />

                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />
                        <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={formData.password} />

                        <Button onClick={() => history("/login")} mt="32" ph="8" textAlign="center" fontSize="12px" fontWeight="bold" children={<TextLocalized children="msgAlreadyRegistered" />} />

                        <ButtonFlat mt="48" action={sendRequest} label="actionRegister" fontColor="color-white" />
                    </Container>
                </Card>
            </ContainerFlex>
        </Container>
    );
};

export default RegisterView;