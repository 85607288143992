import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Container, TextLocalized, ContainerFlex, Button } from '../components/styled/Styled';
import img_home from '../img/background-home.png';

import partner1 from '../img/partner1.png';
import partner2 from '../img/partner2.png';
import partner3 from '../img/partner3.png';
import partner4 from '../img/partner4.png';
import partner5 from '../img/partner5.png';

import { useNavigate } from 'react-router-dom';

import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import TrainingResourceView from './training/TrainingResourceView';
import NetworkingView from './networking/NetworkingView';
import { UserContext } from '../components/providers/UserProvider';

const HomeView = () => {
    const [networkings, setNetworkings] = useState([]);
    const [training, setTraining] = useState([]);
    let { state } = useContext(UserContext);

    let history = useNavigate();

    useEffect(() => {
        HttpConnection(HttpOperations.networkings,
            item => setNetworkings(item.item),
            error => console.log(error), HttpMethod.get);

        HttpConnection(HttpOperations.trainings,
            item => setTraining(item.item),
            error => console.log(error), HttpMethod.get);
    }, [])

    return (
        <Container color="color-white">
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" color="color-primary-a50" pv="150" justify="center" fontWeight="bold" fontSize="37px" fontColor="color-white" textAlign="center">
                    <ContainerFlex width="12" justify="center" textAlign="center" children={<TextLocalized width="12" children="msgHomeTitleOne" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgHomeSubTitle" />} />
                </ContainerFlex>
            </Container>

            <ContainerFlex width="12" justify="center" pv="48">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="color-black" textAlign="center">
                    <TextLocalized children="msgSection1" />
                </Container>
                <ContainerFlex width="12" lg="8" textAlign="center" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgHomeSection1Sub" />} />

                {state.login ?
                    <Fragment>
                        <ContainerFlex width="12" justify="center" align="center">
                            <Button onClick={() => history("/networking")} mx="16" ph="64" pv="16" color="color-black" hoverColor="color-black-dark" radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                                <TextLocalized children="msgHomeSection1Btn" />
                            </Button>
                        </ContainerFlex>
                        <NetworkingView items={networkings.slice(0, 4)} />
                    </Fragment>
                    : <ContainerFlex width="12" justify="center" align="center" mt="32">
                        <Button onClick={() => history("/register")} mx="16" ph="64" pv="16" color="color-primary" hoverColor="color-primary-dark" radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                            <TextLocalized children="msgNotRegister" />
                        </Button>
                    </ContainerFlex>
                }
            </ContainerFlex>

            <ContainerFlex width="12" justify="center" pv="48">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="color-black" textAlign="center" children={<TextLocalized children="msgSection2" />} />
                <Container width="12" mt="16" textAlign="center" children={<TextLocalized children="msgHomeSection2Sub" />} />

                <ContainerFlex width="12" justify="center" align="center" mt="8">
                    <Button onClick={() => history("/training")} mx="16" ph="64" pv="16" color="color-black" hoverColor="color-black-dark" radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                        <TextLocalized children="msgHomeSection2Btn" />
                    </Button>
                </ContainerFlex>

                <TrainingResourceView items={training.slice(0, 4)} />

            </ContainerFlex>

            <ContainerFlex width="12" pv="48" justify="center">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="color-black" textAlign="center">
                    <TextLocalized children="msgHomeSection3" />
                </Container>

                <ContainerFlex width="12" lg="10" justify="center" align="center" mv="32">
                    <img alt="partner-5" width="220" style={{ padding: '32px' }} src={partner1} />
                    <img alt="partner-4" width="220" style={{ padding: '32px' }} src={partner2} />
                    <img alt="partner-3" width="220" style={{ padding: '32px' }} src={partner3} />
                    <img alt="partner-2" width="220" style={{ padding: '32px' }} src={partner4} />
                    <img alt="partner-1" width="220" style={{ padding: '32px' }} src={partner5} />
                </ContainerFlex>

                <ContainerFlex width="12" justify="center" align="center" mb="48">
                    <Button onClick={() => window.open("http://exceliving.eu/contact/", "_blank")}
                        mx="16" ph="64" pv="16" color="color-primary" hoverColor="color-primary-dark"
                        radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                        <TextLocalized children="msgHomeSection3Btn" />
                    </Button>
                </ContainerFlex>
            </ContainerFlex>

        </Container>
    );
};

export default HomeView;