import React, { useContext, useState } from "react"
import styled from 'styled-components'
import { LanguageContext } from "../providers/LanguageProvider";

/* UTIL */
const widthResponsive = (attr) => {
    const { width, xs, sm, lg, xl } = attr;
    const mwidth = `${width ? width === "wrap" ? " col" : `col-${width}` : ``}${xs ? xs === "wrap" ? " col-xs" : ` col-xs-${xs}` : ``}${sm ? sm === "wrap" ? " col-sm" : ` col-sm-${sm}` : ``}${lg ? lg === "wrap" ? " col-lg" : ` col-lg-${lg}` : ``}${xl ? xl === "wrap" ? " col-xl" : ` col-xl-${xl}` : ``}`;
    return mwidth;
}

const marginAndPadding = (props) => {
    const { px = 0, pt, pr, pb, pl, ph, pv } = props;
    const { mx = 0, mt, mr, mb, ml, mh, mv } = props;
    const padding = `${pt ? pt : pv ? pv : px}px ${pr ? pr : ph ? ph : px}px ${pb ? pb : pv ? pv : px}px ${pl ? pl : ph ? ph : px}px`;
    const margin = `${mt ? mt : mv ? mv : mx}px ${mr ? mr : mh ? mh : mx}px ${mb ? mb : mv ? mv : mx}px ${ml ? ml : mh ? mh : mx}px`;

    return `
        padding: ${padding};
        margin: ${margin};
    `;
}

const displayItem = (props) => {
    const { display = "auto", displaySm = "auto", displayMd = "auto", displayLg = "auto", displayXl = "auto" } = props;
    return `
        display: ${display};

        @media (min-width: 576px) {
            display: ${displaySm};
        }

        @media (min-width: 768px) {
            display: ${displayMd};
        }

        @media (min-width: 992px) {
            display: ${displayLg};
        }

        @media (min-width: 1200px) {
            display: ${displayXl};
        }
    `
}

const textFormat = (props) => {
    const { fontColor, fontWeight } = props;
    const { fontSize, fontSizeSm, fontSizeMd, fontSizeLg, fontSizeXl } = props;
    const { textAlign, textAlignSm, textAlignMd, textAlignLg, textAlignXl } = props;
    const { lineHeight, wordSpacing, letterSpacing } = props;
    const { textTransform } = props;

    return `
        ${fontColor ? `color: var(--${fontColor});` : ``}
        ${fontWeight ? `font-weight: ${fontWeight};` : ``}
        ${fontSize ? `font-size: ${fontSize};` : ``}
        ${textAlign ? `text-align: ${textAlign};` : ``}
        ${lineHeight ? `line-height: ${lineHeight};` : ``}
        ${wordSpacing ? `word-spacing: ${wordSpacing};` : ``}
        ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ``}
        ${textTransform ? `text-transform: ${textTransform};` : ``}

        @media (min-width: 576px) {
            ${fontSizeSm ? `font-size: ${fontSizeSm};` : ``}
            ${textAlignSm ? `text-align: ${textAlignSm};` : ``}
        }

        @media (min-width: 768px) {
            ${fontSizeMd ? `font-size: ${fontSizeMd};` : ``}
            ${textAlignMd ? `text-align: ${textAlignMd};` : ``}
        }

        @media (min-width: 1144px) {
            ${fontSizeLg ? `font-size: ${fontSizeLg};` : ``}
            ${textAlignLg ? `text-align: ${textAlignLg};` : ``}
        }

        @media (min-width: 1200px) {
            ${fontSizeXl ? `font-size: ${fontSizeXl};` : ``}
            ${textAlignXl ? `text-align: ${textAlignXl};` : ``}
        }
    `;
}

/* COMPONENT CONTAINER */
const FmuiContainer = styled.div`
    ${props => {
        //Set default values
        const { position = 'initial', top, right, bottom, left } = props;
        const { height, minwidth, maxwidth, minheight, maxheight, overflowY = "initial" } = props;
        const { color, colorEnd, clip = "initial" } = props;
        const { elevation } = props;
        const { image, imgWidth, imgHeight, imgMode = "cover", imgPosition = "center" } = props;
        const { ellipsis = false } = props;
        const { mixBlendMode } = props;

        return `
        position: ${position};
        display: block;
        ${top ? `top: ${top};` : ``}
        ${right ? `right: ${right};` : ``}
        ${bottom ? `bottom: ${bottom};` : ``}
        ${left ? `left: ${left};` : ``}

        ${minwidth ? `min-width: ${minwidth};` : ``}
        ${minheight ? `min-height: ${minheight};` : ``}
        ${maxwidth ? `max-width: ${maxwidth};` : ``}
        ${maxheight ? `max-height: ${maxheight};` : ``}
        ${height ? `height: ${height};` : ``}

        ${marginAndPadding(props)}
        background: ${colorEnd ? `linear-gradient(var(--${color}), var(--${colorEnd}));` : `var(--${color})`};
        ${image ? `background-image: url(${image}); background-size: ${imgWidth ? `${imgWidth} ${imgHeight}` : imgMode}; background-position: ${imgPosition};` : ""}
        box-sizing: border-box;
        overflow-y: ${overflowY};
        ${elevation ? ` z-index:${elevation};` : ''}
        background-clip: ${clip};

        ${mixBlendMode ? `mix-blend-mode:${mixBlendMode}` : ''}

        ${textFormat(props)}
        ${displayItem(props)}

        ${ellipsis ? 'overflow: hidden; text-overflow: ellipsis; white-space: nowrap;' : ''}
    `;
    }}
`;

export const Container = (attr) => <FmuiContainer {...attr}
    className={`${widthResponsive(attr)}${attr.extras ? ` ${attr.extras}` : ""}`} />;

/* COMPONENT CONTAINER-FLEX */
const FmuiContainerFlex = styled(FmuiContainer)`
    ${props => {
        //Set defaults values
        const { multiline = true } = props;
        const { direction = "row", justify = "flex-start", align = "flex-start", alignContent = "auto" } = props;
        const { display = "flex", displaySm = "flex", displayMd = "flex", displayLg = "flex", displayXl = "flex" } = props;
        const { expand = false } = props;

        return `
        flex-wrap: ${multiline ? "wrap" : "nowrap"};
        flex-direction: ${direction};
        ${expand ? 'flex: 1;' : ''}
        justify-content: ${justify};
        align-items: ${align};
        align-content: ${alignContent};

        display: ${display};

        @media (min-width: 576px) {
            display: ${displaySm};
        }

        @media (min-width: 768px) {
            display: ${displayMd};
        }

        @media (min-width: 992px) {
            display: ${displayLg};
        }

        @media (min-width: 1200px) {
            display: ${displayXl};
        }
    `;
    }}
`;

export const ContainerFlex = (attr) => <FmuiContainerFlex {...attr}
    className={`${widthResponsive(attr)}${attr.extras ? ` ${attr.extras}` : ""}`} />;

/* COMPONENT CARD */
const FmuiCard = styled(FmuiContainer)`${props => cardProps(props)}`;
const FmuiCardFlex = styled(FmuiContainerFlex)`${props => cardProps(props)}`;

const cardProps = (props) => {
    const { radius = "8px", radiustl, radiustr, radiusbr, radiusbl } = props;
    const { border = "none", borderWidth, borderColor } = props;
    const { shadowOn = false, shadow = "0 0 0 1px rgba(205,210,223,0.15), 0 2px 11px 0 rgba(0,0,22,0.2)" } = props;

    return `
        ${border !== 'none' ? `border: ${borderWidth} ${border} var(--${borderColor})` : ''};
        border-top-left-radius: ${radiustl ? radiustl : radius};
        border-top-right-radius: ${radiustr ? radiustr : radius};
        border-bottom-right-radius: ${radiusbr ? radiusbr : radius};
        border-bottom-left-radius: ${radiusbl ? radiusbl : radius};
        box-shadow: ${shadowOn ? shadow : ``};
    `;
}

export const Card = (attr) => {
    const { flex = false } = attr;
    return flex ? <FmuiCardFlex {...attr} className={widthResponsive(attr)} /> : <FmuiCard {...attr} className={widthResponsive(attr)} />;
}

/* COMPONENT BUTTON */
const FmuiButton = styled(FmuiCard)`${props => buttonProps(props)}`;
const FmuiButtonFlex = styled(FmuiCardFlex)`${props => buttonProps(props)}`;

const buttonProps = (props) => `
    cursor: pointer;
    overflow-y:hidden;
    & > label {
        cursor: pointer;
    }
    
    &:hover {
        background: var(--${props.hoverColor ?? props.color});
        color: var(--${props.fontHoverColor ?? props.fontColor});
    }
`;

export const Button = (attr) => {
    const { flex = false } = attr;
    return (flex ? <FmuiButtonFlex {...attr} className={widthResponsive(attr)} /> : <FmuiButton {...attr} className={widthResponsive(attr)} />);
}

/* COMPONENT TEXT-LOCALIZED */
export const TextLocalized = (attr) => {
    const languageContext = useContext(LanguageContext);
    const { children } = attr;

    const text = languageContext.dictionary[children];

    return <label dangerouslySetInnerHTML={{ __html: text }} />
}

export const CustomInput = ({
    placeholder, direction, width = "wrap", textSize = "10px", title,
    titlem, titlemt, titleml, titlemr, titlemb, titlemh, titlemv,
    titlep, titlept, titlepl, titlepb, titlepr, titleph, titlepv,
    inputm, inputmt, inputml, inputmr, inputmb, inputmh, inputmv,
    inputp, inputpt, inputpl, inputpb, inputpr, inputph, inputpv,
    value, disabled = false, ...rest
}) => {
    const [clicked, setClicked] = useState(false);
    const titleMargins = { mx: titlem, mt: titlemt, ml: titleml, mr: titlemr, mb: titlemb, mh: titlemh, mv: titlemv, };
    const titlePaddings = { px: titlep, pt: titlept, pl: titlepl, pb: titlepb, pr: titlepr, ph: titleph, pv: titlepv, };
    const inputMargins = { mx: inputm, mt: inputmt, ml: inputml, mr: inputmr, mb: inputmb, mh: inputmh, mv: inputmv, };
    const inputPaddings = { px: inputp, pt: inputpt, pl: inputpl, pb: inputpb, pr: inputpr, ph: inputph, pv: inputpv, };

    return <Card width={width}  {...inputMargins} {...inputPaddings}
        flex
        height="58px" align="center"
        radius="12px"
        color={(clicked || value !== "") && !disabled ? "color-white" : "color-gray"}
        border="solid"
        borderColor={clicked || value !== "" ? "color-primary" : "color-gray-dark"}
        borderWidth="1px"
        onFocus={() => setClicked(true)} onBlur={() => setClicked(false)}>
        <Container width="12">
            {
                clicked || value !== ""
                    ? <Container fontSize={textSize} fontColor={"color-primary"} textTransform="uppercase" {...titleMargins} {...titlePaddings}
                        children={<TextLocalized children={title} />} />
                    : null
            }
            <input style={{ padding: "0 16px" }} type="text" value={value} width="12" placeholder={placeholder} disabled={disabled} {...rest} />
        </Container>
    </Card>
}

export const AreaInput = ({
    placeholder, direction, width = "wrap", textSize = "10px", title,
    titlem, titlemt = "10", titleml, titlemr, titlemb, titlemh, titlemv,
    titlep, titlept, titlepl, titlepb, titlepr, titleph, titlepv,
    inputm, inputmt, inputml, inputmr, inputmb, inputmh, inputmv,
    inputp, inputpt, inputpl, inputpb, inputpr, inputph, inputpv,
    value, ...rest
}) => {
    const [clicked, setClicked] = useState(false);
    const titleMargins = { mx: titlem, mt: titlemt, ml: titleml, mr: titlemr, mb: titlemb, mh: titlemh, mv: titlemv, };
    const titlePaddings = { px: titlep, pt: titlept, pl: titlepl, pb: titlepb, pr: titlepr, ph: titleph, pv: titlepv, };
    const inputMargins = { mx: inputm, mt: inputmt, ml: inputml, mr: inputmr, mb: inputmb, mh: inputmh, mv: inputmv, };
    const inputPaddings = { px: inputp, pt: inputpt, pl: inputpl, pb: inputpb, pr: inputpr, ph: inputph, pv: inputpv, };

    return <Card width={width}   {...inputMargins} {...inputPaddings}
        flex
        align="center"
        radius="12px"
        color={clicked || (value && value !== "") ? "color-white" : "color-gray"}
        border="solid"
        borderColor={clicked || (value && value !== "") ? "color-primary" : "color-gray-dark"}
        borderWidth="1px"
        onFocus={() => setClicked(true)} onBlur={() => setClicked(false)}>
        <Container width="12">
            {
                clicked || value !== ""
                    ? <Container fontSize={textSize} fontColor={"color-primary"} textTransform="uppercase" {...titleMargins} {...titlePaddings}
                        children={<TextLocalized children={title} />} />
                    : null
            }
            <textarea rows={clicked || value ? 5 : 1} style={{ padding: "16px" }} type="text" value={value} width="12" maxLength={2500} placeholder={placeholder} {...rest} />
        </Container>
    </Card>
}

export const SelectInput = ({
    placeholder, direction, width = "wrap", textSize = "10px", title,
    titlem, titlemt, titleml, titlemr, titlemb, titlemh, titlemv,
    titlep, titlept, titlepl, titlepb, titlepr, titleph, titlepv,
    inputm, inputmt, inputml, inputmr, inputmb, inputmh, inputmv,
    inputp, inputpt, inputpl, inputpb, inputpr, inputph, inputpv,
    value, options, ...rest
}) => {
    const [clicked, setClicked] = useState(false);
    const titleMargins = { mx: titlem, mt: titlemt, ml: titleml, mr: titlemr, mb: titlemb, mh: titlemh, mv: titlemv, };
    const titlePaddings = { px: titlep, pt: titlept, pl: titlepl, pb: titlepb, pr: titlepr, ph: titleph, pv: titlepv, };
    const inputMargins = { mx: inputm, mt: inputmt, ml: inputml, mr: inputmr, mb: inputmb, mh: inputmh, mv: inputmv, };
    const inputPaddings = { px: inputp, pt: inputpt, pl: inputpl, pb: inputpb, pr: inputpr, ph: inputph, pv: inputpv, };

    const { dictionary } = useContext(LanguageContext)

    return <Card width={width}  {...inputMargins} {...inputPaddings}
        flex
        height="58px" align="center"
        radius="12px"
        color={clicked || value !== "" ? "color-white" : "color-gray"}
        border="solid"
        borderColor={clicked || value !== "" ? "color-primary" : "color-gray-dark"}
        borderWidth="1px"
        onFocus={() => setClicked(true)} onBlur={() => setClicked(false)}>
        <Container width="12">
            {
                clicked || value !== ""
                    ? <Container fontSize={textSize} fontColor={"color-primary"} textTransform="uppercase" {...titleMargins} {...titlePaddings}
                        children={<TextLocalized children={title} />} />
                    : null
            }
            <select style={{ padding: "0px 16px" }} value={value} {...rest}>
                <option value="" children={placeholder} />
                {options.map((opt, index) => <option key={index} value={dictionary[opt]} children={dictionary[opt]} />)}
            </select>
        </Container>
    </Card>
}

const FmuiSpinner = styled.div`
${props => {
        const { borderColor = "color-white", borderWidth = "4px", size = "30px" } = props;
        return `
            display: inline-block;
            position: relative;
            width: ${size};
            height: ${size};

          & div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: ${size};
            height: ${size};
            border: ${borderWidth} solid var(--${borderColor});
            border-radius: 50%;
            animation: rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: var(--${borderColor}) transparent transparent transparent;
          }

          & div:nth-child(1) {
            animation-delay: -0.45s;
          }

          & div:nth-child(2) {
            animation-delay: -0.3s;
          }
          
          & div:nth-child(3) {
            animation-delay: -0.15s;
          }
          @keyframes & {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `
    }} 
`;

export const SpinnerLoader = (attr) => <FmuiSpinner {...attr} >
    <div /><div /><div /><div />
</FmuiSpinner>

export const TextHtml = ({ children }) => <label dangerouslySetInnerHTML={{ __html: children }} />
