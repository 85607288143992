import { UserData } from '../providers/UserProvider';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';

export const checkLoginUser = (user) => {
    var storageUser = getStorageUser();
    return storageUser && storageUser.id === user.id;
}

export const updateStorageUser = (data) => {
    var local = getStorageUser()
    data.token = local.token;

    var encrypted = AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_SEC_KEY}`);
    localStorage.setItem(UserData.user, encrypted);
}

export const saveStorageUser = (user) => {
    console.log(`SAVE: ${user}`)
    var encrypted = AES.encrypt(JSON.stringify(user), `${process.env.REACT_APP_SEC_KEY}`);
    localStorage.setItem(UserData.user, encrypted);
}

export const getStorageUser = () => {
    var encrypted = localStorage.getItem(UserData.user);
    if (encrypted) {
        try {
            var bytes = AES.decrypt(encrypted, `${process.env.REACT_APP_SEC_KEY}`);
            var user = JSON.parse(bytes.toString(encUTF8))
            return user ? user : null
        } catch (error) {
            console.log(error)
            localStorage.removeItem(UserData.user)
            console.error("unknow user")
        }
    }
    return null;
}

export const getUserToken = () => {
    var storageUser = getStorageUser();
    return (storageUser) ? storageUser.token : "";
}

export const verifyEmailFormat = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const checkValues = (item, exclude = []) => {
    let allRequired = true;
    Object.entries(item).forEach(([key, val]) => {
        if (!exclude.includes(key) && val == "") {
            allRequired = false;
        }
    })
    return allRequired;
}